import React from 'react';
import { BsInstagram } from 'react-icons/bs';
import { FaLinkedin, FaGithub } from 'react-icons/fa';

const SocialMedia = () => (
  <div className="app__social">
    <a href="https://github.com/olivodeveloper/" target="_blank" rel="noreferrer">
      <div>
      {/* <a href="https://olivo.dev/" target="_blank" rel="noreferrer"> */}
        <FaGithub />
      </div>
    </a>
    <a href="https://www.linkedin.com/in/fjolivo/" target="_blank" rel="noreferrer">
      <div>
      {/* <a href="https://olivo.dev/" target="_blank" rel="noreferrer"> */}
        <FaLinkedin />
      </div>
    </a>
    <a href="https://www.instagram.com/olivodev/" target="_blank" rel="noreferrer">
      <div>
      {/* <a href="https://olivo.dev/" target="_blank" rel="noreferrer"> */}
        <BsInstagram />
      </div>
    </a>
  </div>
);

export default SocialMedia;